import {
    Box, Button,
    Card,
    Container, Dialog, DialogTitle,
    FormControlLabel,
    Input,
    InputLabel, Modal,
    Paper,
    Stack,
    Switch, TextField,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import ReactRouterPrompt from 'react-router-prompt';
import axios from "axios";
import {API_BASE} from "./config";
import {acquireToken} from "./fusionauth";
import img1 from "./assets/IMG-20220710-WA0022.jpg";

interface Person {
    id: number,
    name: String,
    is_plus_one: boolean,
    attending: boolean,
}

interface Song {
    id: number,
    artist: String,
    title: String,
}

interface Details {
    dietary_requirements: String,
    comment: String,
}

interface Data {
    guests: Array<Person>,
    songs: Array<Song>,
    details: Details,
}

const WeddingDetails = () => {
    const [data, setData] = useState<Data | undefined>(undefined);

    useEffect(() => {
        const retrieveData = async () => {
            const token = await acquireToken();

            const response = await axios.request<Data>({
                url: `${API_BASE}/rsvp`,
                method: 'GET',
                headers: {"authorization": `Bearer ${token}`},
            });

            let data = response.data;

            while (data.songs.length < 3) {
                data.songs.push({id: -1, artist: "", title: ""});
            }

            setData(response.data);
        }

        retrieveData();
    },
    []);

    const [formState, setFormState] = useState<"unchanged" | "modified" | "saving">("unchanged");
    useEffect(() => {
        const handler = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            event.returnValue = "";
        };

        if (formState !== "unchanged") {
            window.addEventListener("beforeunload", handler);
            // clean it up, if the dirty state changes
            return () => {
                window.removeEventListener("beforeunload", handler);
            };
        }
        return () => {};
    }, [formState]);

    const changeGuestAttending = (guestId: number) => {
        if (!data) return;

        const currentGuestData = data.guests.find(guest => guest.id === guestId) as Person;
        const currentGuestDataIndex = data.guests.indexOf(currentGuestData);

        setData({
            ...data,
            guests: [...data.guests.slice(0, currentGuestDataIndex), {...currentGuestData, attending: !currentGuestData.attending}, ...data.guests.slice(currentGuestDataIndex + 1)]
        });
        setFormState("modified");
    }

    const changeGuestName = (guestId: number, newName: String) => {
        if (!data) return;

        const currentGuestData = data.guests.find(guest => guest.id === guestId) as Person;
        const currentGuestDataIndex = data.guests.indexOf(currentGuestData);

        setData({
            ...data,
            guests: [...data.guests.slice(0, currentGuestDataIndex), {...currentGuestData, name: newName}, ...data.guests.slice(currentGuestDataIndex + 1)]
        });
        setFormState("modified");
    }

    const changeSongArtist = (index: number, newVal: String) => {
        if (!data) return;

        const currentSongData = data.songs[index];

        setData({
            ...data,
            songs: [...data.songs.slice(0, index), {...currentSongData, artist: newVal}, ...data.songs.slice(index + 1)]
        });
        setFormState("modified");
    }

    const changeSongTitle = (index: number, newVal: String) => {
        if (!data) return;

        const currentSongData = data.songs[index];

        setData({
            ...data,
            songs: [...data.songs.slice(0, index), {...currentSongData, title: newVal}, ...data.songs.slice(index + 1)]
        });
        setFormState("modified");
    }

    const changeDietaryRequirements = (newVal: String) => {
        if (!data) return;

        setData({
            ...data,
            details: {
                ...data.details,
                dietary_requirements: newVal,
            },
        });
        setFormState("modified");
    }

    const changeComments = (newVal: String) => {
        if (!data) return;

        setData({
            ...data,
            details: {
                ...data.details,
                comment: newVal,
            },
        });
        setFormState("modified");
    }

    const save = async () => {
        const token = await acquireToken();

        const response = await axios.request<Data>({
            url: `${API_BASE}/rsvp`,
            method: 'POST',
            headers: {"authorization": `Bearer ${token}`},
            data
        });
        setFormState("unchanged");
    }

    return (
        <>
            <ReactRouterPrompt when={formState != "unchanged"}>
                {({ isActive, onConfirm, onCancel }) => (
                    <Dialog open={isActive}>
                        <DialogTitle>Leave page?</DialogTitle>
                        <Box sx={{m: 3}}>
                            <Typography>Changes you made may not be saved.</Typography>
                            <Typography>Are you sure you want to leave?</Typography>
                        </Box>
                        <Box textAlign={"right"} sx={{m: 1}}>
                            <Button onClick={onConfirm}>Leave</Button>
                            <Button onClick={onCancel}>Cancel</Button>
                        </Box>
                    </Dialog>
                )}
            </ReactRouterPrompt>
            <Container sx={{mb: 2}}>
                <Typography variant="h4" sx={{mt: 1, mb: 2}}>Wedding Details (RSVP)</Typography>
                <img src={img1} style={{maxWidth: 1024, width: "100%"}}/>
                <Box>
                    <Typography align="left" sx={{mb: 1}}>We have managed to get ourselves all organised and we are getting hitched!! YAY!!!</Typography>
                    <Typography align="left" sx={{mb: 1}}>Doing away with the usual convention of sending snail mail invitations we have opted to provide this website as your invitation to come along and be part of the fun.</Typography>
                    <Typography align="left" sx={{mb: 1}}>Confirm below if you are able to attend, if you or anyone in your group has dietary requirements and if there are any songs you’d like to hear through the event.</Typography>
                    <Typography align="left" sx={{mb: 1}}>Please explore the website for more information including details of the planned 'hangover brunch', however please don’t hesitate to contact us directly if you have any questions.</Typography>
                    <Typography align="left" sx={{mb: 1}}>Alison, Scott and Amelia xx</Typography>
                </Box>
                <Stack direction="row" sx={{mb: 3, display: 'flex'}}>
                    <Box sx={{display: 'flex', flex: 1}}>
                        <Box>
                            <Typography align="left" fontWeight={500} fontSize="1.2em">Date:</Typography>
                            <Typography align="left">
                                6th April 2023
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', flex: 1}}>
                        <Box>
                            <Typography align="left" fontWeight={500} fontSize="1.2em">Time:</Typography>
                            <Typography align="left">
                                Ceremony to start at 3pm
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', flex: 1}}>
                        <Box>
                            <Typography align="left" fontWeight={500} fontSize="1.2em">Venue:</Typography>
                            <Typography align="left">
                                Last One Inn<br />
                                113 Great Ocean Rd, Anglesea VIC 3230
                            </Typography>
                            <Typography align="left"><a href="https://www.google.com/maps/place/Last+One+Inn/@-38.4096538,144.1841253,17z/data=!3m1!4b1!4m5!3m4!1s0x6ad47d842d38724f:0x623f39964fee28c7!8m2!3d-38.4096612!4d144.1863092">Map Location</a></Typography>
                        </Box>
                    </Box>
                </Stack>
                <Box textAlign="left" width="100%">
                    {data && (
                        <>
                            <Typography align="left" fontWeight={500} fontSize="1.2em">Guests:<Typography variant='caption' sx={{ml: 1, verticalAlign: 'middle'}}>(Select those that are attending and enter names for extra attendees)</Typography></Typography>
                            <Stack>
                                {data.guests.map(
                                    guest => (<FormControlLabel key={guest.id} control={<Switch checked={guest.attending} onClick={() => changeGuestAttending(guest.id)} />} label={guest.is_plus_one ? <TextField value={guest.name} onChange={(val) => changeGuestName(guest.id, val.target.value)} /> : guest.name} />)
                                )}
                            </Stack>
                            <Typography align="left" fontWeight={500} fontSize="1.2em" marginTop="15px">Songs:</Typography>
                            <Stack>
                                {Array.from(Array(3).keys()).map(index => (<Box key={index} sx={{display: 'flex', mb: 1}}><TextField label="Artist" value={data.songs[index]?.artist} onChange={(val) => changeSongArtist(index, val.target.value)} sx={{display: 'flex', flex: 1, mr: 1}}  /><TextField label="Title" value={data.songs[index]?.title} onChange={(val) => changeSongTitle(index, val.target.value)} sx={{display: 'flex', flex: 1}} /></Box>))}
                            </Stack>
                            <Typography align="left" fontWeight={500} fontSize="1.2em" marginTop="15px">Other Details:</Typography>
                            <Stack sx={{mb: 2}}>
                                <TextField sx={{mb: 1}} id="dietary" label="Dietary Requirements" value={data.details?.dietary_requirements} onChange={(val) => changeDietaryRequirements(val.target.value)} />
                                <TextField id="comment" label="Comments" value={data.details?.comment} onChange={(val) => changeComments(val.target.value)} />
                            </Stack>
                            <Button variant="contained" onClick={() => save()} disabled={formState == "unchanged"}>Save RSVP</Button>
                        </>
                    )}
                    {!data && (
                        <>
                            Loading data...
                        </>
                    )}
                </Box>
            </Container>
        </>
    );
};

export default WeddingDetails;