import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import './App.css';
import Login from "./Login";
import Main from "./Main";
import {createTheme, ThemeProvider} from "@mui/material";
import {green, orange, purple, red} from "@mui/material/colors";
import {LOGIN_PAGE} from "./config";

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

const App = () => {
    const theme = createTheme({
        palette: {
            primary: {
                main: red[900]
            },
        },
    });

    return (
    <div className="App">
      <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<Main />} />
              <Route path={`/${LOGIN_PAGE}`} element={<Login />} />
            </Routes>
          </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
