import {Box, Container, LinearProgress, Typography} from "@mui/material";
import {ChangeEvent, useEffect, useState} from "react";
import axios from "axios";
import {v4 as uuidv4} from 'uuid';
import {API_BASE} from "./config";
import {acquireToken} from "./fusionauth";

const CHUNK_SIZE = 1048576 * 1; //its 1MB, increase the number measure in mb

function submitForm(contentType: string, data: any, setResponse: Function) {
    axios({
        url: `${API_BASE}/upload`,
        method: 'POST',
        data: data,
        headers: {
            'Content-Type': contentType
        }
    }).then((response) => {
        setResponse(response.data);
    }).catch((error) => {
        setResponse("error");
    })
}

const Photos = () => {
    const [file, setFile] = useState<File | null>(null);
    const [fileGuid, setFileGuid] = useState("");
    const [fileSize, setFileSize] = useState(0);
    const [fileChunkCount, setFileChunkCount] = useState(0);
    const [fileBeginningOfChunk, setFileBeginningOfChunk] = useState(0);
    const [fileEndOfChunk, setFileEndOfChunk] = useState(CHUNK_SIZE);
    const [fileProgress, setFileProgress] = useState(0);
    const [counter, setCounter] = useState(1);

    useEffect(() => {
        if (fileSize > 0) {
            fileUpload();
        }
    }, [file, fileProgress]);

    const resetChunkProperties = () => {
        setFileProgress(0);
        setCounter(1);
        setFileBeginningOfChunk(0);
        setFileEndOfChunk(CHUNK_SIZE);
    }

    const getFileContext = (e: ChangeEvent<HTMLInputElement>) => {
        resetChunkProperties();
        const _file = e.target.files?.[0];
        if (!_file) {
            return;
        }

        setFileSize(_file.size)

        const _totalCount = _file.size % CHUNK_SIZE == 0 ? _file.size / CHUNK_SIZE : Math.floor(_file.size / CHUNK_SIZE) + 1; // Total count of chunks will have been upload to finish the file
        setFileChunkCount(_totalCount)

        const _fileID = uuidv4();
        setFileGuid(_fileID)

        setFile(_file)
    }

    const fileUpload = () => {
        if (file == null) {
            return;
        }

        setCounter(counter + 1);
        if (counter <= fileChunkCount) {
            var chunk = file.slice(fileBeginningOfChunk, fileEndOfChunk);
            uploadChunk(chunk)
        }
    }

    const uploadChunk = async (chunk: Blob) => {
        try {
            const token = await acquireToken();
            const response = await axios.post(`${API_BASE}/photos/UploadChunk`, chunk, {
                params: {
                    upload_id: fileGuid,
                    chunk: counter,
                    chunk_count: fileChunkCount,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = response.data;
            if (data.isSuccess) {
                setFileBeginningOfChunk(fileEndOfChunk);
                setFileEndOfChunk(fileEndOfChunk + CHUNK_SIZE);
                if (counter == fileChunkCount) {
                    console.log('Process is complete, counter', counter)

                    await uploadCompleted();
                } else {
                    var percentage = (counter / fileChunkCount) * 100;
                    setFileProgress(percentage);
                }
            } else {
                console.log('Error Occurred:', data.errorMessage)
            }

        } catch (error) {
            // debugger
            console.log('error', error)
        }
    }

    const uploadCompleted = async () => {
        const token = await acquireToken();
        const formData = new FormData();
        formData.append('fileName', fileGuid);

        const response = await axios.post(
            `${API_BASE}/photos/UploadComplete`,
            formData,
            {
                params: {
                    upload_id: fileGuid,
                    filename: file?.name,
                },
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                data: formData,
            }
        );

        const data = response.data;
        if (data.isSuccess) {
            setFileProgress(100);
        }
    }

    return (
        <>
            <Typography variant="h4" sx={{mt: 1, mb: 2}}>Photo Book</Typography>
            <Container>
                <Box textAlign="left">
                    <Typography sx={{mb: 1}}>
                        Please come back later. A service will be put up where you will be able to upload pictures
                        you took for us to remember. They will be looked through and approved for anyone who joined
                        us for the occasion to be able to check them out.
                    </Typography>

                    <input type="file" name="file" onChange={getFileContext} />
                    <LinearProgress variant="determinate" value={fileProgress} />
                    {/*<button onClick={fileUpload}>Upload</button>*/}
                </Box>
            </Container>
        </>
    );
}

export default Photos;