import {Box, Container, List, ListItem, Typography} from "@mui/material";
import img1 from "./assets/IMG-20220801-WA0005.jpg"

const AccommodationDetails = () => {
    return (
        <>
            <Container>
                <Typography variant="h4" sx={{mt: 1, mb: 2}}>Accommodation Details</Typography>
                <img src={img1} style={{maxWidth: 600, width: "100%"}} />
                <Box textAlign="left">
                    <Typography sx={{mb: 1}}>
                        We appreciate that Anglesea is not just around the corner, it's a bit far for an Uber and you may need to stay overnight. There are various options for accommodation but to assist we have reserved rooms at the following places:-
                    </Typography>
                    <List sx={{ listStyleType: 'disc', pl: 4 }}>
                        <ListItem sx={{ display: 'list-item' }}>
                            Anglesea River Motel is very close to the venue (85m - stumbling distance), they have 1 bedroom rooms for $160 per night for the Thursday night.
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            Great Ocean Road Resort is also very close (maybe 200m more than Anglesea River Motel), they have 1 bedroom rooms for $230 and 2 bedroom apartments from $300 to $330 a night for the Thursday night.
                        </ListItem>
                    </List>
                    <Typography sx={{mb: 1}}>
                        To book one of these places ring 5263 3363, let them know that you’re booking to attend our wedding and they will allocate you one of the rooms on hold and waiver the minimum booking requirements (its apparently upto 4 nights at that time of year).
                    </Typography>
                    <Typography>
                        Check them out here:
                    </Typography>
                    <List sx={{ listStyleType: 'disc', pl: 4 }}>
                        <ListItem sx={{ display: 'list-item' }}>
                            <a href="https://www.angleseariversidemotel.com.au/?gclid=EAIaIQobChMI-vfnkfrW-AIVf5lmAh1cJgvgEAAYASAAEgJY6PD_BwE">Anglesea Riverside Motel</a>
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <a href="https://www.greatoceanroadresort.com.au/?gclid=EAIaIQobChMIrYinpfrW-AIVvpVLBR3R_wVGEAAYASAAEgKFOfD_BwE">Great Ocean Road Resort</a>
                        </ListItem>
                    </List>
                </Box>
            </Container>
        </>
    );
}

export default AccommodationDetails;