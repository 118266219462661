import {
    Box,
    Button,
    Container, Dialog, DialogTitle,
    FormControlLabel,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import img1 from "./assets/IMG_8938.jpg"
import {useEffect, useState} from "react";
import {acquireToken} from "./fusionauth";
import axios from "axios";
import {API_BASE} from "./config";
import ReactRouterPrompt from "react-router-prompt";

interface DayAfterData {
    attending: number,
    comment: String,
}

const DayAfter = () => {
    const [data, setData] = useState<DayAfterData | undefined>(undefined);

    useEffect(() => {
            const retrieveData = async () => {
                const token = await acquireToken();

                const response = await axios.request<DayAfterData>({
                    url: `${API_BASE}/day-after`,
                    method: 'GET',
                    headers: {"authorization": `Bearer ${token}`},
                });

                setData(response.data);
            }

            retrieveData();
        },
        []);

    const changeAttending = (attending: number) => {
        if (!data) return;

        setData({
            ...data,
            attending: attending,
        });
        setFormState("modified");
    }

    const changeComments = (newVal: String) => {
        if (!data) return;

        setData({
            ...data,
            comment: newVal,
        });
        setFormState("modified");
    }

    const [formState, setFormState] = useState<"unchanged" | "modified" | "saving">("unchanged");
    useEffect(() => {
        const handler = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            event.returnValue = "You have unsaved information. Are you sure you want to leave?";
        };

        if (formState !== "unchanged") {
            window.addEventListener("beforeunload", handler);
            // clean it up, if the dirty state changes
            return () => {
                window.removeEventListener("beforeunload", handler);
            };
        }
        return () => {};
    }, [formState]);

    const save = async () => {
        const token = await acquireToken();

        const response = await axios.request<DayAfterData>({
            url: `${API_BASE}/day-after`,
            method: 'POST',
            headers: {"authorization": `Bearer ${token}`},
            data
        });
        setFormState("unchanged");
    }

    return (
        <>
            <ReactRouterPrompt when={formState != "unchanged"}>
                {({ isActive, onConfirm, onCancel }) => (
                    <Dialog open={isActive}>
                        <DialogTitle>Leave page?</DialogTitle>
                        <Box sx={{m: 3}}>
                            <Typography>Changes you made may not be saved.</Typography>
                            <Typography>Are you sure you want to leave?</Typography>
                        </Box>
                        <Box textAlign={"right"} sx={{m: 1}}>
                            <Button onClick={onConfirm}>Leave</Button>
                            <Button onClick={onCancel}>Cancel</Button>
                        </Box>
                    </Dialog>
                )}
            </ReactRouterPrompt>
            <Container sx={{mb: 2}}>
                <Typography variant="h4" sx={{mt: 1, mb: 2}}>Day-After Party</Typography>
                <img src={img1} style={{maxWidth: 400, width: "100%"}} />
                <Box textAlign="left">
                    <Typography sx={{mb: 1}}>
                        The morning/day after the wedding we will be holding a gathering at the The Deck, which is a great space out the back of the Great Ocean Road Resort. We plan to soak up some of the alcohol with food, while chilling near the trampoline play area Jumpz while the 'kids' enjoy playing.
                    </Typography>
                    <Typography sx={{mb: 1}}>
                        Details on The Deck and Jumpz can be found <a href="https://www.jumpzanglesea.com.au/">here</a>, they have advised that they will be able to reserve us some space and offer a brunch menu if people are interested. If you are planning on attending please let us know below and if you think you would prefer a brunch menu rather than the standard menu.
                    </Typography>
                    <Typography sx={{mb: 1}}>
                        This is just for a rough count of attendees. You are not locked into a decision by indicating your attendance.
                    </Typography>
                </Box>

                <Box textAlign="left" width="100%">
                    {data && (
                        <>
                            <Stack sx={{mb: 2}}>
                                <ToggleButtonGroup value={data.attending} exclusive={true} onChange={(e, value) => {changeAttending(value);}} sx={{mb: 1}}>
                                    <ToggleButton value={0}>Not Attending</ToggleButton>
                                    <ToggleButton value={1}>Standard Menu</ToggleButton>
                                    <ToggleButton value={2}>Brunch Menu</ToggleButton>
                                </ToggleButtonGroup>
                                <TextField id="additionalInfo" label="Additional Info" value={data.comment} onChange={(val) => changeComments(val.target.value)} />
                            </Stack>
                            <Button variant="contained" onClick={() => save()} disabled={formState == "unchanged"}>Save</Button>
                        </>
                    )}
                    {!data && (
                        <>
                            Loading data...
                        </>
                    )}
                </Box>
            </Container>
        </>
    );
}

export default DayAfter;