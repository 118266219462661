import {useEffect, useState} from "react";
import Dashboard from "./Dashboard";
import axios from "axios";
import {API_BASE, LOGIN_PAGE} from "./config";
import {LoginResponse} from "./Interfaces/LoginResponse";
import {
    AppBar,
    Box,
    Container,
    Toolbar,
    Typography
} from "@mui/material";
import CelebrationIcon from "@mui/icons-material/Celebration";
import guard_dogs from "./assets/IMG-20210814-WA0005.jpg"

const Main = () => {
    const [loginResponse, setLoginResponse] = useState<LoginResponse | null | undefined>(undefined);

    useEffect(() => {
        const checkLogin = async () => {
            try {
                if (sessionStorage.getItem("auth.access_token") && parseInt(sessionStorage.getItem("auth.exp") as string) > (new Date().getTime())) {
                    const response = await axios.request<LoginResponse>({
                        url: `${API_BASE}/login`,
                        method: 'GET',
                        headers: {"authorization": `Bearer ${sessionStorage.getItem("auth.access_token")}`},
                    });
                    setLoginResponse(response.data);
                } else {
                    setLoginResponse(null);
                }
            }
            catch(e) {
                setLoginResponse(null);
            }
        }

        checkLogin();
    },
    []);

    return (
        <div>
            {loginResponse === undefined && <p>Checking login...</p>}
            {loginResponse === null && (
                <>
                    <AppBar position="static">
                        <Container maxWidth="xl">
                            <Toolbar disableGutters>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="a"
                                    href="/"
                                    sx={{
                                        mr: 2,
                                        display: { xs: 'none', md: 'flex' },
                                        fontFamily: 'Roboto',
                                        fontWeight: 700,
                                        letterSpacing: '.1rem',
                                        color: 'inherit',
                                        textDecoration: 'none',
                                    }}
                                >
                                    WE DO
                                    <CelebrationIcon sx={{display: {xs: 'none', md: 'flex'}, ml: 1}} />
                                </Typography>

                                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                </Box>
                                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                    <Typography
                                        variant="h5"
                                        noWrap
                                        component="a"
                                        href=""
                                        sx={{
                                            mr: 1,
                                            fontFamily: 'roboto',
                                            fontWeight: 700,
                                            letterSpacing: '.1rem',
                                            color: 'inherit',
                                            textDecoration: 'none',
                                        }}
                                    >
                                        WE DO
                                        <CelebrationIcon sx={{ml: 1}} />
                                    </Typography>
                                </Box>
                                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                </Box>

                                <Box sx={{ flexGrow: 0 }}>
                                </Box>
                            </Toolbar>
                        </Container>
                    </AppBar>
                    <div>
                        <Typography variant="h4" sx={{mt: 1, mb: 0}}>
                            Let's Party: Alison's & Scott's Wedding
                        </Typography>
                        <Container>
                            <img src={guard_dogs} style={{width: "100%", maxWidth: 600}} />
                            <Typography sx={{mt: 1, mb: 2}}>
                                This site is protected by our Guard Dogs. If you are not supposed to be here they will wake up and bark at you until you get annoyed and leave.
                            </Typography>
                            <iframe id="loginframe" style={{border: 1, borderStyle: "solid"}} height={500} src={`${LOGIN_PAGE}?${window.location.hash}`} title="Login" />
                        </Container>
                    </div>
                </>
            )}
            {loginResponse && <Dashboard loginResponse={loginResponse} />}
        </div>
    )
}

export default Main;