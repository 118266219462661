import {LOGIN_PAGE} from "./config";

export const acquireToken = async () => {
    //Check if token has expired
    if (sessionStorage.getItem("auth.access_token") && parseInt(sessionStorage.getItem("auth.exp") as string) > (new Date().getTime())) {
        return sessionStorage.getItem("auth.access_token");
    }

    //If token has expired, then request a new token

    //create iframe and use it to authenticate
    // let silentAuthIframe = window.document.getElementById("silentAuthIframe") as HTMLIFrameElement;
    // if (!silentAuthIframe) {
    //     silentAuthIframe = window.document.createElement("iframe");
    //     silentAuthIframe.setAttribute("id", "silentAuthIframe");
    // }
    // silentAuthIframe.style.display = "none";
    // silentAuthIframe.src = `https://auth.wedo.party/oauth2/authorize?client_id=dd362889-e529-4814-9fd3-37de259d75a4&redirect_uri=${window.location.origin}/${LOGIN_PAGE}&response_type=code`;
    // document.body.appendChild(silentAuthIframe);

    //login will put new token in session

    //otherwise return token from session
}
