import {Box, Container, Typography} from "@mui/material";
import img1 from "./assets/20200301_121021.jpg"

const QuestionsAndAnswers = () => {
    return (
        <>
            <Container>
                <Typography variant="h4" sx={{mt: 1, mb: 2}}>Questions & Answers</Typography>
                <img src={img1} style={{maxWidth: 400, width: "100%"}} />
                <Box textAlign="left">
                    <Typography variant="h6">
                        What is the dress code?
                    </Typography>
                    <Typography sx={{mb: 2}}>
                        Obviously, knowing us this will be a very formal affair.....not. The dress code is smart casual, remembering this is Melbourne so we recommend planning for everything from a blistering hot day to a blizzard and everything in between!!
                    </Typography>
                </Box>
                <Box textAlign="left">
                    <Typography variant="h6">
                        Can I bring a plus one?
                    </Typography>
                    <Typography sx={{mb: 2}}>
                        We want everyone to have fun, if you want to bring someone along to the party who isn’t invited on the main page or you want to bring someone different then give us a shout and I’m sure we can work something out 😊 (within reason, obviously)
                    </Typography>
                </Box>
                <Box textAlign="left">
                    <Typography variant="h6">
                        Are children welcome?
                    </Typography>
                    <Typography sx={{mb: 2}}>
                        Yes, this is an all ages event but we ask parents and carers to have plans in place for how long you think the littler people should stay. Children should be included on the main page, if we’ve missed anyone off, again let us know and we can sort out.
                    </Typography>
                </Box>
                <Box textAlign="left">
                    <Typography variant="h6">
                        Are pets allowed?
                    </Typography>
                    <Typography sx={{mb: 2}}>
                        Dixie and Snoop will grace us with their appearance for a short period during and after the ceremony after which they will be taken to be cared for by Brave Paws while we enjoy the rest of the festivities. No other pets are invited, sorry.
                    </Typography>
                </Box>
                <Box textAlign="left">
                    <Typography variant="h6">
                        Will there be a gift registry?
                    </Typography>
                    <Typography sx={{mb: 1}}>
                        We are so excited to have our favourite people come along and celebrate with us on our special day, your presence is the greatest present.
                    </Typography>
                    <Typography sx={{mb: 2}}>
                        We do recognise however that some people may want to give something more and as we really don't need another toaster we will have a wishing well to go towards our EuroTrip (Scottie does know).
                    </Typography>
                </Box>
                <Box textAlign="left">
                    <Typography variant="h6">
                        I have further questions
                    </Typography>
                    <Typography sx={{mb: 1}}>
                        Please don’t hesitate to contact us if you have any questions, the best way to contact us is via call or text - Alison 0406 049 836 or Scott 0411 057 660
                    </Typography>
                </Box>
            </Container>
        </>
    );
}

export default QuestionsAndAnswers;