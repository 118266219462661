import {Box, Container, Typography} from "@mui/material";

const NotFound = () => {
    return (
        <>
            <Typography variant="h4" sx={{mt: 1, mb: 2}}>Page Not Found</Typography>
            <Container>
                <Box textAlign="left">
                    <Typography sx={{mb: 1}}>
                        In front of you is an abyss, you stare into it and no page can be found. You don't want to step in any further or you may be pulled into the abyss and be gone forever. Go back now.
                    </Typography>
                </Box>
            </Container>
        </>
    );
}

export default NotFound;