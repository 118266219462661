import {Link, Route, Routes} from "react-router-dom";
import WeddingDetails from "./WeddingDetails";
import AccommodationDetails from "./AccommodationDetails";
import DayAfter from "./DayAfter";
import Photos from "./Photos";
import QuestionsAndAnswers from "./QuestionsAndAnswers";
import {
    AppBar,
    Avatar,
    Box,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import CelebrationIcon from "@mui/icons-material/Celebration";
import MenuIcon from '@mui/icons-material/Menu';
import {useState} from "react";
import NotFound from "./NotFound";
import {LoginResponse} from "./Interfaces/LoginResponse";
import Admin from "./Admin";
import {deepOrange} from "@mui/material/colors";

const pages = [
    {title: 'Wedding', to: "/", show_to_all: true},
    {title: 'Accommodation', to: "/accommodation", show_to_all: true},
    {title: 'Day After', to: "/after", show_to_all: true},
    {title: 'Photo Book', to: "/photos", show_to_all: true},
    {title: 'Q & A', to: "/qa", show_to_all: true},
    {title: 'Admin', to: "/admin"}
];

const Dashboard = (props: {loginResponse: LoginResponse}) => {
    const logout = async () => {
        sessionStorage.clear();
        window.location.assign(`https://auth.wedo.party/oauth2/logout?client_id=dd362889-e529-4814-9fd3-37de259d75a4`);
    }

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'Roboto',
                                fontWeight: 700,
                                letterSpacing: '.1rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            WE DO
                            <CelebrationIcon sx={{display: {xs: 'none', md: 'flex'}, ml: 1}} />
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.filter(page => props.loginResponse.is_admin || page.show_to_all).map((page) => (
                                    <MenuItem key={page.title} component={Link} to={page.to}>
                                        <Typography textAlign="center">{page.title}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <Typography
                                variant="h5"
                                noWrap
                                component="a"
                                href=""
                                sx={{
                                    mr: 1,
                                    fontFamily: 'roboto',
                                    fontWeight: 700,
                                    letterSpacing: '.1rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                WE DO
                                <CelebrationIcon sx={{ml: 1}} />
                            </Typography>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.filter(page => props.loginResponse.is_admin || page.show_to_all).map((page) => (
                                <Button
                                    key={page.title}
                                    component={Link}
                                    to={page.to}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    {page.title}
                                </Button>
                            ))}
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar sx={{ bgcolor: deepOrange[500] }} />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem onClick={async () => {handleCloseUserMenu(); await logout();}}>
                                    <Typography textAlign="center">Logout</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <div>
                <Routes>
                    <Route path="/" element={<WeddingDetails />} />
                    <Route path="/accommodation" element={<AccommodationDetails />} />
                    <Route path="/after" element={<DayAfter />} />
                    <Route path="/photos" element={<Photos />} />
                    <Route path="/qa" element={<QuestionsAndAnswers />} />
                    {props.loginResponse.is_admin && (<Route path="/admin" element={<Admin />} />)}
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
        </>
    )
}

export default Dashboard;
