import {Box, Button, Container, FormControlLabel, Stack, Switch, TextField, Typography} from "@mui/material";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PersonIcon from '@mui/icons-material/Person';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from "@mui/icons-material/PersonAddAlt1";
import {useCallback, useEffect, useState} from "react";
import {acquireToken} from "./fusionauth";
import axios from "axios";
import {API_BASE} from "./config";

interface Gang {
    id: number,
    first_guest_name: String,
    first_guest_email: String,
    first_guest_attending: boolean,
    gang_size: number,
}

interface GangGuest {
    id: number,
    name: String,
    email: String,
    is_plus_one: boolean,
    attending: boolean,
    create_login: boolean,
}

interface AddGuestRequest {
    gang_id: number | undefined,
    name: String,
    email: String,
    is_plus_one: boolean,
    create_login: boolean,
}

interface AddGuestResponse {
    gang_id: number,
    guest_id: number,
}

const Admin = () => {
    const [gangs, setGangs] = useState<Gang[] | undefined>(undefined);
    const [currentGangId, setCurrentGangId] = useState<number | undefined>(undefined);
    const [currentGang, setCurrentGang] = useState<GangGuest[] | undefined>(undefined);

    const retrieveData = async () => {
        const token = await acquireToken();

        const response = await axios.request<Gang[]>({
            url: `${API_BASE}/admin/gangs`,
            method: 'GET',
            headers: {"authorization": `Bearer ${token}`},
        });

        let data = response.data;
        console.log(data);

        setGangs(response.data);
    }

    useEffect(() => {
        retrieveData();
    },
    []);

    useEffect(() => {
        const retrieveGangData = async (gangId: number) => {
            const token = await acquireToken();

            const response = await axios.request<GangGuest[]>({
                url: `${API_BASE}/admin/gangs/${gangId}`,
                method: 'GET',
                headers: {"authorization": `Bearer ${token}`},
            });

            let data = response.data;
            console.log(data);

            setCurrentGang(response.data);
        }

        if (currentGangId) {
            retrieveGangData(currentGangId);
        } else {
            setCurrentGang(undefined);
        }
    }, [currentGangId]);

    const saveChanges = useCallback(async () => {
        console.log(`Saving data for ${currentGangId} ->`, currentGang)

        const token = await acquireToken();

        if (currentGang === undefined) return;

        let gangId = currentGangId;
        for (let guestIndex=0; guestIndex < currentGang.length; guestIndex++) {
            let guest = currentGang[guestIndex];

            if (guest.id > 0) continue; // Don't add guests that are already added. Need edit code for these.

            let newGuest: AddGuestRequest = {
                gang_id: gangId,
                name: guest.name,
                email: guest.email,
                is_plus_one: guest.is_plus_one,
                create_login: guest.create_login,
            };
            const response = await axios.request<AddGuestResponse>({
                url: `${API_BASE}/admin/add_guest`,
                method: 'POST',
                headers: {"authorization": `Bearer ${token}`},
                data: newGuest,
            });

            if (gangId === undefined) {
                gangId = response.data.gang_id;
            }

            let data = response.data;
            console.log("Saved - ", data);
        }

        console.log(`Saving data for ${currentGangId} ->`, currentGang)
        setCurrentGang(undefined);
        setCurrentGangId(undefined);
        retrieveData();
    }, [currentGangId, currentGang]);

    const toggleGuestAttending = useCallback((guestId: number) => {
        if (!currentGang) return;

        setCurrentGang(currentGang.map(guest => {
            return {...guest, attending: guest.id === guestId ? !guest.attending : guest.attending};
        }));
    }, [currentGang]);

    const toggleGuestIsPlusOne = useCallback((guestId: number) => {
        if (!currentGang) return;

        setCurrentGang(currentGang.map(guest => {
            return {...guest, is_plus_one: guest.id === guestId ? !guest.is_plus_one : guest.is_plus_one};
        }));
    }, [currentGang]);

    const toggleGuestCreateLogin = useCallback((guestId: number) => {
        if (!currentGang) return;

        setCurrentGang(currentGang.map(guest => {
            return {...guest, create_login: guest.id === guestId ? !guest.create_login : guest.create_login};
        }));
    }, [currentGang]);

    const updateGuestName = useCallback((guestId: number, val: string) => {
        if (!currentGang) return;

        setCurrentGang(currentGang.map(guest => {
            return {...guest, name: guest.id === guestId ? val : guest.name};
        }));
    }, [currentGang]);

    const updateGuestEmail = useCallback((guestId: number, val: string) => {
        if (!currentGang) return;

        setCurrentGang(currentGang.map(guest => {
            return {...guest, email: guest.id === guestId ? val : guest.email};
        }));
    }, [currentGang]);

    const addPerson = useCallback(() => {
        if (!currentGang) return;

        const nextNumber = currentGang.reduce((prev, curr) => (curr.id <= prev ? curr.id - 1 : prev), -1);

        setCurrentGang([...currentGang, {id: nextNumber, name: "", email: "", attending: false, is_plus_one: false, create_login: false}]);
    }, [currentGang]);

    return (
        <>
            <Typography variant="h4" sx={{mt: 1, mb: 2}}>Administration</Typography>
            <Container>
                {!currentGang &&
                    <Box textAlign="left">
                        <Typography align="left" fontWeight={500} fontSize="1.2em">Gangs: <Button onClick={() => {
                            setCurrentGang([])
                        }}><GroupAddIcon/></Button></Typography>
                        <Stack>
                            {gangs?.map(
                                gang => (<div key={gang.id}>
                                        {gang.first_guest_attending ? <HowToRegIcon/> : <PersonIcon/>}
                                        {`${gang.first_guest_name} (${gang.first_guest_email}) - ${gang.gang_size} guests`}
                                        <Button onClick={() => {
                                            setCurrentGangId(gang.id)
                                        }}><EditIcon/></Button></div>
                                )
                            )}
                        </Stack>
                    </Box>
                }
                {!!currentGang &&
                    <Box textAlign="left">
                        <Typography align="left" fontWeight={500}
                                    fontSize="1.2em">Guests: <Button onClick={() => {addPerson()}}><PersonAddIcon/></Button></Typography>
                        <Stack>
                            {currentGang?.map(
                                guest => (<Box key={guest.id} sx={{mb:1}}>
                                    {<FormControlLabel control={<Switch checked={guest.attending} onClick={() => {toggleGuestAttending(guest.id)}} />} label="Attending" labelPlacement="top" />}
                                    {<TextField label="Name" value={guest.name ?? ""} onChange={(val) => {updateGuestName(guest.id, val.target.value)}} />}
                                    {<TextField label="Email" value={guest.email ?? ""} onChange={(val) => {updateGuestEmail(guest.id, val.target.value)}} />}
                                    {<FormControlLabel control={<Switch checked={guest.is_plus_one} onClick={() => {toggleGuestIsPlusOne(guest.id)}} />} label="+1" labelPlacement="top" />}
                                    {<FormControlLabel control={<Switch checked={guest.create_login} onClick={() => {toggleGuestCreateLogin(guest.id)}} />} label="create login" labelPlacement="top" />}
                                </Box>)
                            )}
                        </Stack>
                        {!!currentGangId && <>
                            <Button onClick={() => saveChanges()}>Save</Button>
                            <Button onClick={() => setCurrentGangId(undefined)}>Cancel</Button>
                        </>}
                        {!currentGangId && <>
                            <Button onClick={() => saveChanges()}>Save</Button>
                            <Button onClick={() => setCurrentGang(undefined)}>Cancel</Button>
                        </>}
                    </Box>
                }
            </Container>
        </>
    )
}

export default Admin;