import { useEffect } from "react";
import {useSearchParams} from "react-router-dom";
import axios from "axios";
import {LOGIN_PAGE} from "./config";

const Login = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const authRedirectCallBack = async () => {
        const code = searchParams.get("code");
        const locale = searchParams.get("locale");
        const userState = searchParams.get("userState");

        if (!(code && locale && userState)) {
            window.location.replace(`https://auth.wedo.party/oauth2/authorize?client_id=dd362889-e529-4814-9fd3-37de259d75a4&redirect_uri=${window.location.origin}/${LOGIN_PAGE}&response_type=code`);
        } else {
            const form = new URLSearchParams();
            form.append("client_id", "dd362889-e529-4814-9fd3-37de259d75a4");
            form.append("client_secret", "JQIogpvy4Y84O4NnPi_w6o7WnwhMabEUFEDDZcDOeIE");
            form.append("code", code);
            form.append("grant_type", "authorization_code");
            form.append("redirect_uri", `${window.location.origin}/${LOGIN_PAGE}`);
            form.append("scope", "offline_access");
            const token = await axios.post("https://auth.wedo.party/oauth2/token",
                form,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    }
                }
            );
            const exp = (new Date().getTime()) + token.data.expires_in * 1000;
            sessionStorage.setItem("auth.access_token", token.data.access_token);
            sessionStorage.setItem("auth.exp", exp.toString());
            window.top?.location.reload();
        }
    }

    useEffect(() => {
        authRedirectCallBack();
    },
    []);

    return (
        <div>
            Loading...
        </div>
    )
}

export default Login;